
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="添加退货入库"
    :visible.sync="visible"
    width="1000px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
<el-row>
        <el-col :span="24">
            <el-form-item label="仓库" prop="warehouseId">
                <el-select ref="warehouseId"
                                v-model="row.warehouseId"
                                prop="warehouseId"
                                placeholder="请选择仓库"
                                clearable
                                filterable
                                :filter-method="listWmsWarehouse"
                            >
                            <el-option
                                v-for="item in optionsOfWmsWarehouse"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="24">
            <el-form-item label="客户" prop="customerId">
                <el-select ref="customerId"
                                v-model="row.customerId"
                                prop="customerId"
                                placeholder="请选择客户"
                                clearable
                                filterable
                                :filter-method="listWmsCustomer"
                            >
                            <el-option
                                v-for="item in optionsOfWmsCustomer"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="24">
            <el-form-item label="供应商" prop="supplierId">
                <el-select ref="supplierId"
                                v-model="row.supplierId"
                                prop="supplierId"
                                placeholder="请选择供应商"
                                clearable
                                filterable
                                :filter-method="listWmsSupplier"
                            >
                            <el-option
                                v-for="item in optionsOfWmsSupplier"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="24">
            </el-col>
            </el-row>

            <el-row>
            <el-col>
            <el-form-item label="明细" prop="details">
    <vxe-toolbar perfect>
        <template #buttons>
            <el-button type="text" icon="el-icon-plus" @click="() => row.details.push({})">新增</el-button>
        </template>
    </vxe-toolbar>

<vxe-table
        :data="row.details"
        empty-text=""
        :scroll-y="{enabled: false}"
        :edit-config="{trigger: 'click', mode: 'cell'}"
        ref="detailsTable"
>

            <vxe-column field="id" title="ID" :visible="false" ></vxe-column>





        <vxe-column field="inOutOrderCode" title="单号" >
            <template slot-scope="scope">
                    <el-input v-model="scope.row.inOutOrderCode" placeholder="请输入单号" clearable
                              :disabled="false"
                              :style="{width: '50px'}"></el-input>
            </template>
        </vxe-column>


        <vxe-column field="skuId" title="商品" >
            <template slot-scope="scope">
                    <el-select ref="skuId"
                               :disabled="false"
                               v-model="scope.row.skuId"
                               prop="skuId"
                               placeholder="请选择商品"
                               clearable
                               filterable
                               :filter-method="listWmsSku"
                               :style="{width: '120px'}"
                    >
                        <el-option
                                v-for="item in optionsOfWmsSku"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        ></el-option>
                    </el-select>
            </template>
        </vxe-column>










        <vxe-column field="unitId" title="单位" >
            <template slot-scope="scope">
                    <el-select ref="unitId"
                               :disabled="false"
                               v-model="scope.row.unitId"
                               prop="unitId"
                               placeholder="请选择单位"
                               clearable
                               filterable
                               :filter-method="listWmsUnit"
                               :style="{width: '120px'}"
                    >
                        <el-option
                                v-for="item in optionsOfWmsUnit"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        ></el-option>
                    </el-select>
            </template>
        </vxe-column>




        <vxe-column field="quantity" title="数量" >
            <template slot-scope="scope">
                    <el-input-number v-model="scope.row.quantity"
                                     :disabled="false"
                                     placeholder="请输入数量" clearable
                                     :controls="false"
                                     :step="1"
                                     :style="{width: '120px'}"></el-input-number>
            </template>
        </vxe-column>






        <vxe-column field="binId" title="货位" >
            <template slot-scope="scope">
                    <el-select ref="binId"
                               :disabled="false"
                               v-model="scope.row.binId"
                               prop="binId"
                               placeholder="请选择货位"
                               clearable
                               filterable
                               :filter-method="listWmsBinLocation"
                               :style="{width: '120px'}"
                    >
                        <el-option
                                v-for="item in optionsOfWmsBinLocation"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        ></el-option>
                    </el-select>
            </template>
        </vxe-column>
















    <vxe-column title="操作">
        <template slot-scope="scope">
            <el-button type="text" icon="el-icon-delete" @click="() => row.details.splice(scope.$index, 1)">删除</el-button>
        </template>
    </vxe-column>
</vxe-table>            </el-form-item>
            </el-col>
            </el-row>

            <el-col>
        </el-col>
            </el-row>
            <el-row>

        <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="row.remark" placeholder="请输入备注"
                    clearable
                    type="textarea"
                    :rows="3"
                    :style="{width: '200px'}"
                ></el-input>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { addWmsRefundInOrder } from "@/api/wms/wmsRefundInOrder"

import { listWmsWarehouse } from "@/api/wms/wmsWarehouse"

import { listWmsCustomer } from "@/api/wms/wmsCustomer"

import { listWmsSupplier } from "@/api/wms/wmsSupplier"

import { listWmsInOutOrder } from "@/api/wms/wmsInOutOrder"
import { listWmsSku } from "@/api/wms/wmsSku"
import { listWmsUnit } from "@/api/wms/wmsUnit"
import { listWmsBinLocation } from "@/api/wms/wmsBinLocation"

export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            model: null,
            code: null,
            status: 0,
            warehouseId: null,
            warehouseName: null,
            customerId: null,
            customerName: null,
            supplierId: null,
            supplierName: null,
            details: [],
            remark: null,
            loginUserId: null,
            loginUserName: null,
            completeTime: null,
      },
      rules: {
            model: [
                { required: true, message: '请输入单据场景!'},
            ],
            warehouseId: [
                { required: true, message: '请输入仓库!'},
            ],
            details: [
                { required: true, message: '请输入明细!'},
            ],
            details: [
                { required: true, message: '请输入明细!'},
            ],
      },
        enums: {},
        enumMaps: {},
        optionsOfWmsWarehouse: [],

        optionsOfWmsCustomer: [],

        optionsOfWmsSupplier: [],

        optionsOfWmsInOutOrder: [],
        optionsOfWmsSku: [],
        optionsOfWmsUnit: [],
        optionsOfWmsBinLocation: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
    this.listWmsWarehouse()
    this.listWmsCustomer()
    this.listWmsSupplier()
    this.listWmsInOutOrder()
    this.listWmsSku()
    this.listWmsUnit()
    this.listWmsBinLocation()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsRefundInOrderStatus')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen() {
        this.visible = true
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },

    listWmsWarehouse(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsWarehouse(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsWarehouse = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsCustomer(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsCustomer(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsCustomer = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsSupplier(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSupplier(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSupplier = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsInOutOrder(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsInOutOrder(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsInOutOrder = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    listWmsSku(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSku(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSku = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    listWmsUnit(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsUnit(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsUnit = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    listWmsBinLocation(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsBinLocation(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsBinLocation = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        addWmsRefundInOrder(param).then(res => {
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
.el-select {
    width: 200px;
}
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
